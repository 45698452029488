<template>
  <div class="basket content-container">
    <div class="dfEnd">
      <a-button @click="clearHigtligh()">Снять выделение</a-button>
      <a-button class="ml-10" @click="highlightCur()">Выделить все</a-button>
    </div>
    <a-table
      class="ordertable mt-10"
      bordered
      :columns="columns"
      :data-source="products"
      :rowKey="e => e.id"
      :scroll="{ x: 1000 }"
      :pagination="{
        defaultPageSize: 10,
        hideOnSinglePage: true,
        selectCuePag: selectCuePag,
        onChange: takeNumPag,
      }"
      :loading="loading"
    >
      <!--      :row-selection="{
          fixed: false,
          hideDefaultSelections: true,
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
-->
      <span slot="check" slot-scope="text, record, index">
        <a-checkbox :checked="text" @change="handleCheck($event, index)" />
      </span>
      <span slot="customArticle">Артикул</span>
      <span slot="count" slot-scope="text, record">
        <a-input-number
          :default-value="text"
          :value="text"
          :min="1"
          @change="handleCount($event, record.id)"
        />
      </span>

      <span slot="priceWithNDS" slot-scope="item">
        <template>{{ item | formatPrice }}</template>
      </span>
      <span slot="calculatedPrice" slot-scope="text, record">
        {{
          Number(
            (
              record.calculatedPrice *
              record.count *
              record.availableCount
            ).toFixed(2)
          ).toString() | formatPrice
        }}
      </span>
      <span slot="weight" slot-scope="text, record">
        {{ record.attributes.weight ? record.attributes.weight.value : "0" }}
      </span>
      <span slot="dimensions" slot-scope="text, record">
        {{ record.attributes.height ? record.attributes.height.value : "0" }}
        x
        {{ record.attributes.width ? record.attributes.width.value : "0" }}
        x
        {{ record.attributes.length ? record.attributes.length.value : "0" }}
      </span>
      <span slot="comment" slot-scope="text, record, index">
        <a-input
          placeholder="Ваш комментарий"
          :value="text"
          @input="handleComment($event, index)"
        />
      </span>
      <span slot="actionMark" slot-scope="text, record" class="action">
        <a-popconfirm
          title="Уверены, что хотите удалить товар из корзины?"
          ok-text="Удалить"
          cancel-text="Отмена"
          placement="topLeft"
          @confirm="confirmDelete(record.id)"
        >
          <a-button
            type="danger"
            ghost
            shape="circle"
            icon="delete"
            size="small"
          />
        </a-popconfirm>
        <!-- <a-checkbox :checked="text" @change="handleDelete($event, index)" /> -->
      </span>
    </a-table>
    <a-button type="primary" class="oformButton" @click="submit">
      Оформить
    </a-button>
    <a-modal
      v-model="showModal"
      title="Цена некоторых товаров в корзине изменилась"
      cancel-text="Удалить эти товары из корзины"
      ok-text="Пересчитать корзину"
      @cancel="hideModal"
      @ok="hideModal"
      :maskClosable="false"
      :closable="false"
      :footer="null"
      :width="800"
    >
      <div v-if="recalculatePriceProducts.length" class="recalculate-price">
        <a-alert
          message="Внимание"
          description="Цена этих товаров была изменена:"
          type="warning"
          banner
          show-icon
        />
        <a-table
          :columns="recalculatePriceColumns"
          :data-source="recalculatePriceProducts"
          :pagination="{ defaultPageSize: 10, hideOnSinglePage: true }"
          :rowKey="row => row.number"
          class="ordertable"
        >
          <template slot="product" slot-scope="text, record">
            <span>{{ text }}</span>
            <span class="recalculate-price__code">{{ record.code }}</span>
          </template>
          <template slot="oldPrice" slot-scope="item">
            <span>{{ item }}</span>
          </template>
          <template slot="newPrice" slot-scope="item">
            <span>{{ item }}</span>
          </template>
        </a-table>
        <div class="recalculate-price__buttons">
          <a-button @click="hideModal">Вернуться в корзину</a-button>
          <a-button type="primary" @click="recalculatePrice">
            Пересчитать корзину
          </a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex"
import { notification } from "ant-design-vue"

export default {
  name: "BasketProvider",
  data() {
    return {
      selectedRowKeys: [],
      selectCuePag: 1,
      showModal: false,
      recalculatePriceProducts: [],
      columns: [
        {
          title: "",
          dataIndex: "check",
          width: 30,
          scopedSlots: { customRender: "check" },
        },
        {
          title: "Поставщик",
          dataIndex: "supplier_name",
          width: 110,
        },
        {
          title: "Код ДЦ",
          dataIndex: "dealer_code",
          width: 110,
        },
        {
          title: "Бренд",
          dataIndex: "attributes.brand.value",
          key: "brand",
          width: 110,
        },
        {
          title: "Наименование",
          dataIndex: "description",
          key: "description",
          ellipsis: false,
          width: 200,
        },
        {
          dataIndex: "code",
          slots: { title: "customArticle" },
          scopedSlots: { customRender: "article" },
          width: 90,
        },
        {
          title: "Срок доставки",
          dataIndex: "delivery_date",
          key: "date",
          ellipsis: true,
          width: 130,
        },
        {
          title: "Количество",
          dataIndex: "count",
          scopedSlots: { customRender: "count" },
          width: 100,
        },
        {
          title: "Объем, литров",
          dataIndex: "availableCount",
          ellipsis: true,
          width: 80,
        },
        {
          title: "Стоимость, за литр рублей с НДС",
          dataIndex: "productRef.price_stock",
          scopedSlots: { customRender: "priceWithNDS" },
          width: 120,
        },
        {
          title: "Стоимость за тару, рублей с НДС",
          dataIndex: "price",
          scopedSlots: { customRender: "priceWithNDS" },
          width: 120,
        },
        {
          title: "Стоимость, рублей с НДС",
          dataIndex: "calculatedPrice",
          scopedSlots: { customRender: "calculatedPrice" },
          width: 120,
        },
        {
          title: "Действия",
          dataIndex: "actionMark",
          width: 70,
          scopedSlots: { customRender: "actionMark" },
        },
      ],
      recalculatePriceColumns: [
        {
          title: "Товар",
          dataIndex: "description",
          align: "center",
          scopedSlots: { customRender: "product" },
        },
        {
          title: "Старая цена",
          dataIndex: "calculatedPrice",
          align: "center",
          scopedSlots: { customRender: "oldPrice" },
        },
        {
          title: "Новая цена",
          dataIndex: "newPrice",
          align: "center",
          scopedSlots: { customRender: "newPrice" },
        },
      ],
    }
  },
  watch: {
    recalculatePriceProducts() {
      if (this.recalculatePriceProducts.length > 0) {
        this.showModal = true
      } else {
        this.showModal = false
      }
    },
  },
  mounted() {
    this.getProducts({})

    if (!this.basket) {
      this.clear()
    }
  },
  computed: {
    ...mapGetters({
      loading: "basket/loading",
      products: "basket/products",
      checkedProducts: "basket/checkedProducts",
    }),
  },
  filters: {
    formatPrice(price) {
      const formatter = new Intl.NumberFormat("ru-RU", {
        style: "currency",
        currency: "RUB",
      })
      return formatter.format(parseFloat(price))
    },
  },
  methods: {
    ...mapMutations({
      setField: "basket/SET_PRODUCT_FIELD",
      clear: "basket/CLEAR_BASKET",
      setProducts: "basket/SET_PRODUCTS",
    }),
    ...mapActions({
      getProducts: "basket/getProducts",
      setCheckedProducts: "basket/setCheckedProducts",
      updateProduct: "basket/updateProduct",
      deleteFromBasket: "basket/deleteFromBasket",
      verifyBasket: "basket/verifyBasket",
    }),
    clearHigtligh() {
      for (const index in this.products) {
        const query = { index, key: "check", value: false }
        this.setField(query)
      }
    },
    highlightCur() {
      for (const index in this.products) {
        const query = { index, key: "check", value: true }
        this.setField(query)
      }
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    takeNumPag(selectCuePag) {
      this.selectCuePag = selectCuePag
    },
    handleDelete(e, index) {
      const query = { index, key: "deletemark", value: e.target.checked }
      this.setField(query)
    },
    handleCheck(e, index) {
      const query = { index, key: "check", value: e.target.checked }
      this.setField(query)
    },
    handleCount(value, catalog_product_id) {
      if (value < 1) {
        return
      }
      let obj = this.products.find(x => x.id === catalog_product_id)
      let index = this.products.indexOf(obj)
      const query = { index, key: "count", value }
      this.setField(query)
      this.updateProductData(this.products[index])
    },

    async updateProductData(product) {
      const products = JSON.parse(JSON.stringify(this.products))
      this.setProducts(products)
      this.updateProduct(product)
    },

    handleComment(e, index) {
      const query = { index, key: "comment", value: e.target.value }
      this.setField(query)
    },
    async submit() {
      const response = await this.checkBasket()
      if (response.data.orders) {
        if (response.data.orders.length > 0) {
          notification.open({
            message: "Создано " + response.data.orders.length + " заказов",
            description: "Создано " + response.data.orders.length + " заказов",
            duration: 0,
            style: {
              width: "600px",
              marginLeft: `${100 - 600}px`,
            },
          })
        }
        if (response.data.errors.length > 0) {
          notification.open({
            message: "Не все заказы созданы :",
            description: response.data.errors.join(" "),
            duration: 0,
            style: {
              width: "600px",
              marginLeft: `${100 - 600}px`,
            },
          })
        }
        this.getProducts({})
        return
      }
      if (response.data.data && response.data.data.length > 0) {
        let descr = response.data.data.map(item => {
          return item
        })
        notification.open({
          message: response.data.message,
          description: descr.join(" "),
          duration: 0,
          style: {
            width: "600px",
            marginLeft: `${100 - 600}px`,
          },
        })
        return
      }
      this.$router.push({ name: "OrderRegistration" })
    },
    confirmDelete(basket_product_id) {
      this.deleteFromBasket(basket_product_id)
    },
    async checkBasket() {
      try {
        this.setCheckedProducts()
        const response = await this.verifyBasket({
          products: this.checkedProducts,
        })
        if (response) {
          return response
        }
      } catch (err) {
        const {
          response: {
            data: { errors: recalcPriceProducts = null } = null,
          } = null,
        } = err
        if (recalcPriceProducts) {
          this.$message.warning("Необходим пересчет цены товаров")
          recalcPriceProducts.map(item => {
            const newPrice = item.reason.match(/(?<=\sis\s).+/gm)
            if (newPrice) {
              item.newPrice = parseFloat(newPrice[0])
            }
            return item
          })
          this.findProductsForReculcPrice(recalcPriceProducts)
        }
        return false
      }
    },
    findProductsForReculcPrice(recalcPriceProducts) {
      if (recalcPriceProducts) {
        this.showModal = true
        recalcPriceProducts.map(item => {
          const product = this.basket.products.find(
            product => product.number === item.number
          )
          if (product) {
            product.newPrice = item.newPrice
            this.recalculatePriceProducts.push(product)
          }
        })
      }
    },
    async recalculatePrice() {
      const updatedProducts = JSON.parse(JSON.stringify(this.products))
      updatedProducts.map(item => {
        const recalcProduct = this.recalculatePriceProducts.find(
          product => (product.number = item.number)
        )
        if (recalcProduct) {
          item.calculatedPrice = recalcProduct.newPrice
        }
        if (!item.comment) {
          delete item.comment
        }
        if (item.newPrice) {
          delete item.newPrice
        }
      })
      this.setProducts(updatedProducts)
      await this.updateBasket(this.basket)
      this.recalculatePriceProducts = []
    },
    hideModal() {
      this.showModal = false
    },
  },
}
</script>

<style lang="scss" scoped>
.ordertable {
  ::v-deep .ant-table-tbody > tr {
    cursor: pointer;
  }
}
.ant-table-pagination.ant-pagination {
  display: flex !important;
  float: none !important;
  justify-content: center !important;
}
.oformButton {
  margin-top: 20px;
}
th.column-action,
td.column-action {
  text-align: center !important;
}
</style>
<style lang="scss">
.basket {
  .ant-table-column-title {
    color: rgba(0, 0, 0, 0.65);
    white-space: break-spaces;
  }

  tr {
    color: rgba(0, 0, 0, 0.85);
  }

  th {
    border-radius: 0;
  }

  .action {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.recalculate-price {
  &__buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 10px 0;
    > button {
      &:first-child {
        margin-right: 10px;
      }
    }
  }
  &__code {
    display: block;
    color: rgba(black, 0.5);
    font-size: 12px;
  }
}
.ml-10 {
  margin-left: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.dfEnd {
  display: flex;
  justify-content: end;
}
</style>
